import $ from "jquery";

export default function initNavHideShow() {
  $(document).ready(function () {
    let $navList = $(".nav--main");
    let $footer = $("footer");
    let lastScrollTop = 0;
    let scrollTimeout;

    $(window).on("scroll", function () {
      let scrollTop = $(window).scrollTop();
      let scrollBottom = scrollTop + $(window).height();
      let footerOffsetTop = $footer.offset().top;

      if (scrollTop > lastScrollTop) {
        $navList.addClass("hidden");
      }else if(scrollTop < lastScrollTop || scrollTop<30){
        $navList.removeClass("hidden");
      }
      
      
      // Prevent nav from showing near the footer
      if (scrollBottom >= footerOffsetTop + 200) {
        clearTimeout(scrollTimeout); // Ensure the nav remains hidden near footer
      } else {
        // Delay to make sure the nav doesn't reappear too soon
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          $navList.removeClass("hidden");
        }, 1000); // Adjust delay as needed
      }

      lastScrollTop = scrollTop;
    });
  });
}
