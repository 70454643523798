import $ from "jquery";

const $doc = $(document);
const $mobileNav = $(".js-mobile-nav");
const $hamburger = $(".js-hamburger");
const $body = $("body");


function showMobileNav(event) {
  event.preventDefault();
  $mobileNav.toggleClass("is-active");
  $hamburger.toggleClass("is-active");

  if ($mobileNav.hasClass("is-active")) {
    $hamburger.attr("aria-expanded", "true");
    $body.css("overflow-y", "hidden");
  } else {
    $hamburger.attr("aria-expanded", "false");
    $body.css("overflow", ""); 
    $(".menu-item--active").removeClass("menu-item--active");
    $(".sub-menu-container--active").removeClass("sub-menu-container--active");
    $(".toggled-on").removeClass("toggled-on");
  }
}

export default function initMobileNav() {
  $doc.on("click", ".js-hamburger", showMobileNav);
  $doc.on("keyup", function (event) {
    if ($mobileNav.hasClass("is-active") && event.key == "Escape") {
      $hamburger.attr("aria-expanded", "false");
      $mobileNav.toggleClass("is-active");
      $hamburger.toggleClass("is-active");
      $body.css("overflow", ""); 
    }
  });
  
  $doc.on("focusin", function (event) {
    var $target = $(event.target);
    if (!$target.closest(".js-header-wrapper").length) {
      if ($mobileNav.hasClass("is-active")) {
        $hamburger.attr("aria-expanded", "false");
        $mobileNav.toggleClass("is-active");
        $hamburger.toggleClass("is-active");
        $body.css("overflow", "");
      }
    }
  });
}
