import initGreenSock from '/lib/greensock'
import initHeadroom from '/lib/headroom'
import initMobileNav from '/lib/mobile-nav'
import initLangSelectorMobile from '/lib/lang-selector-mobile'
import initUtilityNav from '/lib/utility-nav'
import initA11yFunctions from '/lib/accessibility'
import initFooterDropdown from '/lib/footer'
import initFooterForm from '/lib/footer-form'
import initLottie from '../404'
import initNavHideShow from './lib/navHideShow'


initGreenSock()
initHeadroom()
initMobileNav()
initLangSelectorMobile()
initUtilityNav()
initA11yFunctions()
initFooterForm()
initFooterDropdown()
initLottie()
initNavHideShow();


