import $ from "jquery";

const $doc = $(document);

function menuDropdownA11y() {
  /*
  Adapted from: An accessible menu for WordPress
  https://github.com/argenteum/accessible-nav-wp
  Licensed GPL v.2 (http://www.gnu.org/licenses/gpl-2.0.html)
  This work derived from:
  https://github.com/WordPress/twentysixteen (GPL v.2)
  https://github.com/wpaccessibility/a11ythemepatterns/tree/master/menu-keyboard-arrow-nav (GPL v.2)
  */

  let menuContainer = $(".js-header");
  let siteHeaderMenu = menuContainer.find(".js-header-wrapper");

  // If you are using WordPress, and do not need to localise your script, or if you are not using WordPress, then uncomment the next line

  let screenReaderText = {
    expand: "Expand child menu",
    collapse: "Collapse child menu",
  };

  let dropdownToggle = $("<button />", {
    class: "dropdown-toggle",
    "aria-expanded": false,
  }).append(
    $("<span />", { class: "u-sr-only", text: screenReaderText.expand }),
  );
  let svgString = `
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
      <g clip-path="url(#clip0_8858_6369)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3302 0.929134C16.7246 1.32358 16.7246 1.96317 16.3302 2.35762L9.59616 9.09163C9.20158 9.48607 8.56212 9.48607 8.16754 9.09163L1.43353 2.35762C1.03908 1.96317 1.03908 1.32358 1.43353 0.929134C1.82811 0.53469 2.46757 0.53469 2.86201 0.929134L8.88188 6.949L14.9017 0.929134C15.2962 0.53469 15.9358 0.53469 16.3302 0.929134H16.3302Z" fill="#FFFFFD"/>
      </g>
      <defs>
        <clipPath id="clip0_8858_6369">
          <rect width="16" height="9.77778" fill="white" transform="translate(0.881836)"/>
        </clipPath>
      </defs>
    </svg>`;

  dropdownToggle.append(svgString);

  // Adds the dropdown toggle button
  $(".menu-item-has-children > a").after(dropdownToggle);

  // Adds aria attribute
  siteHeaderMenu.find(".menu-item-has-children").attr("aria-haspopup", "true");

  // Toggles the sub-menu when dropdown toggle button clicked
  siteHeaderMenu.find(".dropdown-toggle").click(function (e) {
    let screenReaderSpan = $(this).find(".u-sr-only");

    e.preventDefault();
    $(this).toggleClass("toggled-on");
    $(this).nextAll(".sub-menu").toggleClass("toggled-on");

    // jscs:disable
    $(this).attr(
      "aria-expanded",
      $(this).attr("aria-expanded") === "false" ? "true" : "false",
    );
    // jscs:enable
    screenReaderSpan.text(
      screenReaderSpan.text() === screenReaderText.expand
        ? screenReaderText.collapse
        : screenReaderText.expand,
    );
  });

  // Adds a class to sub-menus for styling
  $(".sub-menu .menu-item-has-children")
    .parent(".sub-menu")
    .addClass("has-sub-menu");

  // Keyboard navigation
  $(".menu-item a, button.dropdown-toggle").on("keyup", function (e) {
    if ([37, 38, 39, 40].indexOf(e.keyCode) == -1) {
      return;
    }

    switch (e.keyCode) {
      case 37: // left key
        e.preventDefault();
        e.stopPropagation();

        if ($(this).hasClass("dropdown-toggle")) {
          $(this).prev("a").focus();
        } else {
          if (
            $(this).parent().prev().children("button.dropdown-toggle").length
          ) {
            $(this).parent().prev().children("button.dropdown-toggle").focus();
          } else {
            $(this).parent().prev().children("a").focus();
          }
        }

        if ($(this).is("ul ul ul.sub-menu.toggled-on li:first-child a")) {
          $(this)
            .parents("ul.sub-menu.toggled-on li")
            .children("button.dropdown-toggle")
            .focus();
        }

        break;

      case 39: // right key
        e.preventDefault();
        e.stopPropagation();

        if ($(this).next("button.dropdown-toggle").length) {
          $(this).next("button.dropdown-toggle").focus();
        } else {
          $(this).parent().next().children("a").focus();
        }

        if ($(this).is("ul.sub-menu .dropdown-toggle.toggled-on")) {
          $(this).parent().find("ul.sub-menu li:first-child a").focus();
        }

        break;

      case 40: // down key
        e.preventDefault();
        e.stopPropagation();

        if ($(this).next().length) {
          $(this).next().find("li:first-child a").first().focus();
        } else {
          $(this).parent().next().children("a").focus();
        }

        if (
          $(this).is("ul.sub-menu a") &&
          $(this).next("button.dropdown-toggle").length
        ) {
          $(this).parent().next().children("a").focus();
        }

        if (
          $(this).is("ul.sub-menu .dropdown-toggle") &&
          $(this).parent().next().children(".dropdown-toggle").length
        ) {
          $(this).parent().next().children(".dropdown-toggle").focus();
        }

        break;

      case 38: // up key
        e.preventDefault();
        e.stopPropagation();

        if ($(this).parent().prev().length) {
          $(this).parent().prev().children("a").focus();
        } else {
          $(this)
            .parents("ul")
            .first()
            .prev(".dropdown-toggle.toggled-on")
            .focus();
        }

        if (
          $(this).is("ul.sub-menu .dropdown-toggle") &&
          $(this).parent().prev().children(".dropdown-toggle").length
        ) {
          $(this).parent().prev().children(".dropdown-toggle").focus();
        }

        break;
    }
  });
}

function newWindowLinks() {
  $("a[target='_blank']").append(
    '<span class="u-sr-only">Opens in new window</span>',
  );
}

export default function initA11yFunctions() {
  menuDropdownA11y();
  newWindowLinks();
}
