import $ from "jquery";
const $doc = $(document);

export default function initFooterDropdown() {
    $doc.ready(function (e) {
        const selector = $(".js-footer-dropdown");

        selector.on("click", function (e) {
            $(this).parent().find(".footer__bottom__statement-text").toggle();
            $(this).parent().toggleClass("is-open");
        });
    });
}
