import $ from "jquery";
const $doc = $(document);

export default function initLangSelectorMobile() {
  $doc.ready(function (e) {
    const selector = $(".js-lang-selector");
    
    selector.on("click", function (e) {
      $(this).find(".lang").toggleClass("lang--active");
    });
  });
}
